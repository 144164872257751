import { css } from 'styled-components';
import { ucFirstLetter, camelToKebab } from './strings';

/**
 * Converts any px value to rem using a base value
 *
 * @param {number|string} px px value
 * @param {number|string} base :root/body px value
 * @param {boolean} raw only return the raw value
 * @returns {string|number} rem value in either string or raw unit value
 */
export const pxToRem = (px = 16, base = 16, raw = false) => {
  const pxFloat = parseFloat(px);
  const baseFloat = parseFloat(base);
  if (Number.isNaN(pxFloat) || Number.isNaN(baseFloat)) {
    throw new Error('`pxToRem` only accepts valid number or value strings');
  }
  const fixed = parseFloat((pxFloat / baseFloat).toFixed('3'));
  return raw ? fixed : `${fixed}rem`;
};

/**
 * generates an object of number and string values for
 * converting px to rem
 *
 * @param {number} pxRaw px value
 * @param {number} base :root/body px value
 * @returns {object} dimensions
 * @returns {string} dimensions.px
 * @returns {number} dimensions.pxRaw
 * @returns {string} dimensions.rem
 * @returns {number} dimensions.remRaw
 */
export const calcPxDimensions = (pxRaw = 16, base = 16) => ({
  px: `${pxRaw}px`,
  pxRaw,
  rem: pxToRem(pxRaw, base),
  remRaw: pxToRem(pxRaw, base, true),
});

/**
 * Will prefix/rename all values within a dimension object
 *
 * @param {string} prefix string to prefix all values by
 * @param {object} dimensions object of px and rem values
 * @returns {object} prefixed dimension props
 */
export const prefixDimentionProperties = (prefix = 'foo', dimensions = {}) =>
  Object.keys(dimensions).reduce(
    (list, key) => ({
      ...list,
      [`${prefix}${ucFirstLetter(key)}`]: dimensions[key],
    }),
    {},
  );

/**
 * Flattens an object of dimension properties
 *
 * @param {object} dimensions dimensions object
 * @returns {object} flattened object of dimensions
 */
export const flattenDimensions = (dimensions = {}) =>
  Object.keys(dimensions).reduce(
    (acc, key) => ({
      ...acc,
      ...prefixDimentionProperties(key, dimensions[key]),
    }),
    {},
  );

/**
 * Converts all object keys to namespaced css vars
 * @example
 * { foo: '#fff', barBaz: 123 }
 * // becomes
 * { --dsw-<prefix>-foo: '#fff', '--dsw-<prefix>-bar-baz: 123 }
 *
 * @param {string} prefx var namespace
 * @param {object} obj an object of key/value pairs
 * @returns {object} namespaced vars
 */
export const styleObjectToCssVars = (prefx = 'foo', obj = {}) =>
  Object.keys(obj).reduce(
    (list, key) => ({
      ...list,
      [`--dsw-${prefx}-${camelToKebab(key)}`]: `${obj[key]}`,
    }),
    {},
  );

/**
 * Creates a media query function which uses template literals
 * to define styles
 * https://www.styled-components.com/docs/advanced#media-templates
 *
 * @example
 * const largeBreakpoint = makeMedia('screen', 'max-width', '1000px');
 * styled.div`
 *   margin: 2rem;
 *   ${largeBreakpoint`
 *     margin: 1rem;
 *   `}
 * `
 * // or
 * styled.div`
 *   ${makeMedia('screen', 'max-width', '1000px')`
 *     display: none;
 *   `}
 * `
 *
 * @param {string} type media type
 * @param {string} feature media feature
 * @param {string} width media width including units
 * @returns {function} template literal function
 */
export const makeMedia = (
  type = 'screen',
  feature = 'max-width',
  width = '10rem',
) => (...args) =>
  css`
      @media ${type} and (${feature}: ${width}) {
        ${css(...args)}
      }
    `;
