import React from 'react';
import { css, ThemeProvider as StyledThemeProvider } from 'styled-components';
import { camelToKebab } from './utils/strings';
import {
  makeMedia,
  flattenDimensions,
  styleObjectToCssVars,
} from './utils/styles';

// assumed body computed pixel size
export const BASE_PX = 16;

export const zIndex = {
  layoutHeader: 2,
  // only add named zIndexes above when absolutely required
  // below are some 'nudging' numbers
  zero: 0,
  raised: 1,
  behind: -1,
};

export const getColorVar = (colorName = '') =>
  `var(--ds-color-${camelToKebab(colorName)})`;

export const colorsDesignSystem = {
  // TODO:
  // would be nice to import all css as an object of named css styles, eg:
  // import * as css from '@economist/design-system/../color.css';
  // and then auto-list them here to use in theme props
  /* Brand */
  'economist-red': '--ds-color-economist-red',
  'economist-red-60': '--ds-color-economist-red-60',

  /* Accent */
  'chicago-20': '--ds-color-chicago-20',
  'chicago-30': '--ds-color-chicago-30',
  'chicago-45': '--ds-color-chicago-45',
  'chicago-55': '--ds-color-chicago-55',
  'chicago-90': '--ds-color-chicago-90',
  'chicago-95': '--ds-color-chicago-95',
  'hong-kong-45': '--ds-color-hong-kong-45',
  'hong-kong-55': '--ds-color-hong-kong-55',
  'hong-kong-90': '--ds-color-hong-kong-90',
  'hong-kong-95': '--ds-color-hong-kong-95',
  'tokyo-45': '--ds-color-tokyo-45',
  'tokyo-55': '--ds-color-tokyo-55',
  'tokyo-90': '--ds-color-tokyo-90',
  'tokyo-95': '--ds-color-tokyo-95',
  'singapore-55': '--ds-color-singapore-55',
  'singapore-65': '--ds-color-singapore-65',
  'singapore-75': '--ds-color-singapore-75',
  'singapore-90': '--ds-color-singapore-90',
  'new-york-55': '--ds-color-new-york-55',
  'new-york-65': '--ds-color-new-york-65',
  'new-york-75': '--ds-color-new-york-75',
  'new-york-90': '--ds-color-new-york-90',

  /* Greyscale */
  'london-5': '--ds-color-london-5',
  'london-10': '--ds-color-london-10',
  'london-20': '--ds-color-london-20',
  'london-35': '--ds-color-london-35',
  'london-70': '--ds-color-london-70',
  'london-85': '--ds-color-london-85',
  'london-95': '--ds-color-london-95',
  'london-100': '--ds-color-london-100',

  /* Canvas */
  'los-angeles-85': '--ds-color-los-angeles-85',
  'los-angeles-90': '--ds-color-los-angeles-90',
  'los-angeles-95': '--ds-color-los-angeles-95',
  'paris-85': '--ds-color-paris-85',
  'paris-90': '--ds-color-paris-90',
  'paris-95': '--ds-color-paris-95',
};

export const colorsWebsite = {
  'london-98': '#fafafa',
};

export const colors = {
  ...colorsDesignSystem,
  ...colorsWebsite,
};

export const fonts = {};

export const dimensions = {
  // TODO
};

export const breakpointsMinWidth = {
  small: '22.5rem',
  medium: '37.5rem',
  large: '60rem',
  xlarge: '80rem',
  max: '90rem',
};

export const mediaMinWidth = Object.keys(breakpointsMinWidth).reduce(
  (acc, label) => {
    acc[label] = makeMedia('screen', 'min-width', breakpointsMinWidth[label]);
    return acc;
  },
  {},
);

export const breakpointsMaxWidth = {
  small: '22.4375rem',
  medium: '37.4375rem',
  large: '59.9375rem',
  xlarge: '79.9375rem',
  max: '89.9375rem',
};

export const mediaMaxWidth = Object.keys(breakpointsMaxWidth).reduce(
  (acc, label) => {
    acc[label] = makeMedia('screen', 'max-width', breakpointsMaxWidth[label]);
    return acc;
  },
  {},
);

/**
 * Self-executing function to generate a list of css vars which can be used inside
 * any Styled Components declaration
 *
 */
export const makeCssVars = (() => css`
  :root {
    /* Color vars  */
    ${styleObjectToCssVars('color', colorsWebsite)}

    /* Dimension vars  */
    ${styleObjectToCssVars('dimension', flattenDimensions(dimensions))}
  }
`)();

/**
 * Theme object available in all styled component props
 * @example `${({ theme })} => theme.dimensions.gutter`
 */
export const theme = {
  colors,
  zIndex,
  dimensions,
  getColorVar,
  mediaMaxWidth,
  mediaMinWidth,
};

/**
 * ThemeProvider wrapper to make all theme vars available to styled components
 *
 * @param {object} props any valid React props
 * @returns {object} React component
 */
export const ThemeProvider = (props) => (
  <StyledThemeProvider theme={theme} {...props} />
);
