import slugifyNpm from 'slugify';
import { isBrowser } from './dom';

/**
 * Converts a camel/pascal case string to kebab
 *
 * @param {string} str value to convert
 * @returns {string} the string in kebab format
 */
export const camelToKebab = (str = '') =>
  str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();

/**
 * Make the first letter in a string uppercase
 *
 * @param {string} str string
 * @returns {string} converted string
 */
export const ucFirstLetter = (str = '') =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

/**
 * Slugifies any string
 *
 * @param {string} str any title, label or string
 * @returns {string} slug
 */
export const slugify = (str = '') =>
  slugifyNpm(str, {
    lower: true,
    remove: /[*+~.()'"!:@,]/g,
  });

/**
 * Returns a hashed slug from any string
 *
 * @param {string} str any string or label
 * @returns {string} hashed slug
 */
export const makeUrlHash = (str = '') => `#${slugify(str)}`;

/**
 * Converts a slug to a label, capitalising first letter, first word
 *
 * @param {string} slug slug string
 * @returns {string} the transformed slug
 */
export const slugToLabel = (slug = '') =>
  ucFirstLetter(slug.replace(/[-|/]/g, ' ').trim());

/**
 * Takes a string containing an escaped html entity and returns
 * an unescaped piece of text.
 *
 * @param {string} string string containing escaped html entity
 * @returns {string} unescaped text
 */
export const htmlDecode = (string) => {
  if (isBrowser()) {
    // Creates a HTMLDocument.
    const doc = new DOMParser().parseFromString(string, 'text/html');
    return doc.documentElement.textContent;
  }
  return string;
};
