/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

export const ACTIVE_CLASS_NAME = 'is-active';

const UnstyledLink = ({ to, isActive, className, ...props }) => {
  const nonRoutedLink = /^(#|mailto:|http:|https:)/.test(to);
  const classNames = classnames(className, {
    [ACTIVE_CLASS_NAME]: isActive,
  });
  return nonRoutedLink ? (
    <a href={to} className={classNames} {...props} />
  ) : (
    <GatsbyLink
      to={to}
      className={classNames}
      activeClassName={ACTIVE_CLASS_NAME}
      {...props}
    />
  );
};

UnstyledLink.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};
UnstyledLink.defaultProps = {
  isActive: false,
  className: false,
};

export const Link = styled(UnstyledLink)`
  color: inherit;
`;

export default Link;
