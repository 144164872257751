import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  MenuDisclosure,
  MonogramSquare,
} from '@economist/design-system/dist/umd/common';

import { makeMedia, pxToRem } from '../../utils/styles';

import { isBrowser } from '../../utils/dom';
import { routes, keyCodes } from '../../constants';
import { Link } from '../link/link';

const navBreakpointPx = 1100;
const mediaMenuMinWidth = makeMedia(
  'screen',
  'min-width',
  pxToRem(navBreakpointPx),
);
const mediaMenuMaxWidth = makeMedia(
  'screen',
  'max-width',
  pxToRem(navBreakpointPx - 1),
);

const LogoSquare = styled(MonogramSquare)`
  height: 4rem;
  min-width: 4rem;
  width: 4rem;
`;

const MenuButton = styled(MenuDisclosure)`
  ${mediaMenuMinWidth`
    /* extra specificity needed because external DS styles are
    sometimes declared after component styles */
    &.ds-menu-disclosure {
      display: none;
    }
  `}
`;

const MastheadLinkInner = styled.span``;

const MastheadLink = styled(({ children, ...props }) => (
  <Link {...props}>
    <MastheadLinkInner>{children}</MastheadLinkInner>
  </Link>
))`
  outline: none;
  text-decoration: none;
  ${MastheadLinkInner} {
    border-bottom: 0.125rem solid transparent;
    transition: var(--ds-interactions-transition);
  }
  &:hover ${MastheadLinkInner} {
    border-bottom-color: currentColor;
    color: var(--ds-color-chicago-30);
  }
  &:focus ${MastheadLinkInner} {
    box-shadow: 0 0 0 0.125rem var(--ds-color-hong-kong-55);
    outline: solid transparent;
  }
  &:active ${MastheadLinkInner} {
    border-bottom-color: var(--ds-color-hong-kong-55);
    box-shadow: none;
    color: var(--ds-color-london-5);
  }
`;

const MastheadNavListItem = styled.li`
  margin: 0 0 0 2.5rem;
  &:first-child {
    margin-left: 0;
  }
  .is-active${MastheadLink} {
    font-weight: 700;
  }
`;

const MastheadNavList = styled.ol`
  display: none;
  ${mediaMenuMinWidth`
    display: flex;
    flex-direction: row;
  `}
`;

const MastheadNavListWrapper = styled.div``;

const MastheadTitle = styled.h1`
  margin: 0.125rem 0 0 0.25rem;
  strong {
    flex-basis: 0%;
    font-weight: 700;
    height: 0;
    overflow: hidden;
    width: 0;
  }
  ${MastheadLinkInner} {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  /* needed to ensure specificity */
  /* stylelint-disable-next-line order/order */
  ${() => makeMedia('screen', 'min-width', '25rem')` /* 400px */
    font-size: var(--ds-type-scale-2);
  `}
  ${({ theme }) => theme.mediaMinWidth.medium`
    strong {
      flex-basis: auto;
      height: auto;
      margin-right: 0.2em;
      overflow: visible;
      width: auto;
    }
  `}
  ${mediaMenuMinWidth`
    margin-left: 1.25rem;
  `}
`;

const MastheadMenu = styled.div`
  align-items: center;
  border-left: var(--ds-border-rule);
  display: flex;
  height: calc(100% - 2rem);
  margin: 0 0 0 0.75rem;
  padding: 0 0 0 0.75rem;
  ${mediaMenuMinWidth`
    margin-left: 2.25rem;
    padding-left: 2.25rem;
  `}
`;

const MastheadBar = styled.nav`
  align-items: center;
  border-bottom: var(--ds-border-rule);
  border-top: var(--ds-border-rule);
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  margin: 0 0 0 1rem;
  ${mediaMenuMinWidth`
    justify-content: flex-start;
  `}
`;

const MastheadWrapper = styled.header`
  align-items: stretch;
  color: var(--ds-color-london-5);
  display: flex;
  flex-direction: row;
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-1);
  font-weight: 400;
  justify-content: space-between;
  line-height: var(--ds-type-leading-border-link);
  padding: var(--ds-grid-gutter) var(--ds-grid-gap) 2rem;
  ${({ theme }) => theme.mediaMinWidth.medium`
    padding-left: var(--ds-grid-gutter);
    padding-right: var(--ds-grid-gutter);
  `}
  ${mediaMenuMaxWidth`
    &.is-open {
      background: var(--ds-color-london-20);
      color: var(--ds-color-london-100);

      ${MastheadBar} {
        border-bottom: var(--ds-border-rule--inverse);
        border-top: var(--ds-border-rule--inverse);
      }

      ${MastheadMenu} {
        border-left: var(--ds-border-rule--inverse);
      }

      ${MastheadLink} {
        &:hover ${MastheadLinkInner} {
          color: var(--ds-color-london-85);
        }
      }

      ${MastheadNavListWrapper} {
        background-color: transparent;
        box-sizing: border-box;
        height: calc(100vh - var(--ds-grid-gutter) - 4rem);
        left: 0;
        position: absolute;
        top: calc(var(--ds-grid-gutter) + 4rem);
        width: 100%;
      }

      ${MastheadNavList} {
        background: var(--ds-color-london-20);
        display: flex;
        flex-direction: column;
        padding: 0.5rem var(--ds-grid-gutter) var(--ds-grid-gutter);
      }

      ${MastheadNavListItem} {
        border-bottom: var(--ds-border-rule--inverse);
        margin-left: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;

        ${MastheadLink} {
          display: block;
        }
      }
    }

  `}
`;

export const isLinkActive = (url = '') => {
  const [urlPrimary] = url.split('/').filter(Boolean);
  const [locationPrimary] = isBrowser()
    ? window.location.pathname.split('/').filter(Boolean)
    : '';
  return urlPrimary === locationPrimary;
};

export const Masthead = ({ className, menu }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = (evt) => {
    evt.preventDefault();
    setMenuOpen(!menuOpen);
  };

  const handleOutsideClose = useCallback(({ target, currentTarget }) => {
    if (target === currentTarget) {
      setMenuOpen(false);
    }
  }, setMenuOpen);

  const onKeyup = ({ keyCode }) => {
    switch (keyCode) {
      case keyCodes.ESC: {
        setMenuOpen(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []);

  return (
    <MastheadWrapper
      className={classnames(className, {
        'is-open': menuOpen,
      })}
    >
      <LogoSquare />
      <MastheadBar>
        <MastheadTitle>
          <MastheadLink to={routes.HOME}>
            <strong>The Economist</strong> <span>Design System</span>
          </MastheadLink>
        </MastheadTitle>
        <MastheadMenu>
          <MenuButton
            menuIsOpen={menuOpen}
            inverse={menuOpen}
            onClick={toggleMenuOpen}
          />
          <MastheadNavListWrapper onClick={handleOutsideClose}>
            <MastheadNavList>
              {menu.map(({ slug, navigationLabel, redirectTo }) => {
                const url = redirectTo || slug;
                return (
                  <MastheadNavListItem key={slug}>
                    <MastheadLink to={url} isActive={isLinkActive(url)}>
                      {navigationLabel}
                    </MastheadLink>
                  </MastheadNavListItem>
                );
              })}
            </MastheadNavList>
          </MastheadNavListWrapper>
        </MastheadMenu>
      </MastheadBar>
    </MastheadWrapper>
  );
};

Masthead.defaultProps = {
  className: null,
  menu: [],
};

Masthead.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Masthead;
