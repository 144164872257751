export const SCROLL_DEFAULTS = {
  behavior: 'smooth',
  block: 'start',
  inline: 'nearest',
};

/**
 * Scroll an element into view natively
 *
 * @param {DOMnode} el element to scroll into view
 * @param {object} options scrollIntoView options
 * @returns {undefined}
 */
export const scrollIntoView = (el, options = {}) => {
  if (el && el.scrollIntoView) {
    el.scrollIntoView({ ...SCROLL_DEFAULTS, ...options });
  }
};

/**
 * Wrapper for browser history replaceState when we don't want
 * to use Gatsby or any routing library to trigger a rerender
 *
 * @param {string} url URL to navigate to
 * @returns {undefined}
 */
export const replaceState = (url = '') => {
  if (window.history) window.history.replaceState(null, null, url);
};

/**
 * Wrapper for browser history pushState when we don't want
 * to use Gatsby or any routing library to trigger a rerender
 *
 * @param {string} url URL to navigate to
 * @returns {undefined}
 */
export const pushState = (url = '') => {
  if (window.history) window.history.pushState(null, null, url);
};

/**
 * Helper function to check if the window function had been defined.
 *
 * @returns {bool} if the window object has been defined
 */
export const isBrowser = () => typeof window !== 'undefined';
